import React from 'react';
import Layout from '../comp/Layout';

const VillaCatering = () => (
  <Layout title="Villa Catering">
    {/* <AbsoluteImgBg image={f19villa} smaller landImage />
    <div className={styles.villaCatering}>
      <SeperatedContent
        title={title}
        text={text}
        secondTitle={secondTitle}
        secondText={secondText}
      >
        For further discussion and to plan with us your meal,
        contact us at
        {' '}
        <a className={globalStyles.contactLinkWhite} href="mailto:contact@lasmari.gr">contact@lasmari.gr</a>
        {' '}
        &
        {' '}
        <a className={globalStyles.contactLinkWhite} href="tel:+302663098633">+302663098633</a>
        .;
      </SeperatedContent>
    </div> */}
  </Layout>
);

export default VillaCatering;
